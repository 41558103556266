@use '~@strategies/tokens/color';
@use '~@strategies/tokens/font';
@use '~@strategies/tokens/layout';


.TimelinePanel {
  position: fixed;
  pointer-events: none;
  padding: layout.$padding--small;
  padding-left: 0;

  .Panel {
      margin: 0 0 auto auto;

      &.active {
        height: 100%;
      }
  }

  .color-selector {
    flex: 1 1;
    vertical-align: middle;

    height: 100%;
    margin: 0 0 0 layout.$padding--small;

    text-transform: uppercase;
  }

  .ReactForm {
      &__RadioSlider {
          height: 100%;

          &-option {
              padding: 6px 0;
              font-size: font.$size--small;
          }
      }

      &__Label {
          height: 100%;
      }
  }

  .ReactTimeline {
      &__Scrubber.phase {
        width: 0px;
        border: 1px dashed color.$grey;
        background: none;
      }
  }

  main {
      display: flex;
      flex-direction: column;
      height: 100%;
  }

  .resort-button {
      height: 100%;
      padding: layout.$padding--small;

      color: color.$black;
      font-size: font.$size--small;
      text-transform: uppercase;

      background: color.$grey;
      border-radius: layout.$border-radius--small;
  }

  .project-window {
      height: 100%;
      overflow-y: auto;
  }

    .timeline-menu {
        position: absolute;
        top: calc(28px + #{layout.$padding--small});
        right: 0;
        z-index: 10;

        width: 14px;
        height: 28px;

        background: color.$white;
        border-radius: layout.$border-radius--small;
        border: 1px solid color.$blue--light;
        box-shadow: layout.$box-shadow;
        overflow: hidden;
        cursor: pointer;
        transition: width .25s;

        &:hover {
            width: 120px;
            height: auto;
            transition: width .25s;
        }

        .menu-hamburger {
            width: 3px;
            padding: 3px 0;

            .dot, &:before, &:after {
                content: '';
                display: block;

                width: 100%;
                height: 3px;
                margin: 2px 5px;

                background: color.$grey;
                border-radius: layout.$border-radius--small;
            }

            span {
                position: absolute;
                left: 14px;
                top: 55%;
                transform: translateY(-50%);

                font-size: font.$size--small;
                font-weight: 400;
            }
        }

        ul {
            width: 120px;
            list-style: none;
            padding: 0;
            margin: 0;
            margin-top: 5px;

            button {
                width: 100%;
                text-align: left;
                background: color.$blue--light;
                font-weight: 500;
                font-size: font.$size--small;

                &:hover {
                    color: color.$blue;
                    background: color.$blue--lightest;
                }
            }
        }
    }

}
