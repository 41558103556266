@use '~@strategies/tokens/animation';
@use '~@strategies/tokens/color';
@use '~@strategies/tokens/font';
@use '~@strategies/tokens/layout';


.ProjectList {
    display: flex;
    flex-wrap: wrap;
    gap: layout.$padding--smallest;

    padding: layout.$padding--smallest;

    .ProjectCard {
        flex: 1 1 calc(32% - #{layout.$padding--smallest * 2});

        max-width: 275px;
        min-width: 225px;

        border-radius: layout.$border-radius;

        .inner {
            padding: layout.$padding--smallest;

            border-radius: 0 0 layout.$border-radius layout.$border-radius;
            border: 2px solid color.$grey;
            border-top: 0;
            transition: border-color animation.$speed;
        }

        .clickable-title {
            display: flex;

            padding: layout.$padding--smallest;

            color: color.$white;

            border-radius: layout.$border-radius layout.$border-radius 0 0;
            border: 2px solid rgba(color.$blue, 0);
            border-bottom: 0;
            cursor: pointer;
            transition: border-color animation.$speed;

            h3 {
                flex: 1;

                margin: 0;
                padding-left: layout.$padding--smallest;

                font-weight: font.$weight--regular;
                pointer-events: none;
            }
        }


        &.disabled {
            $border: 2px solid #bdbdbd;

            .clickable-title {
                color: color.$grey;
                border-top: $border;
                border-left: $border;
                border-right: $border;
            }
        }

        &.selected {
            .inner, .clickable-title {
                border-color: color.$blue;//NOTE - selected overrides disabled above
            }
        }

        .row {
            display: flex;

            margin: layout.$padding--smallest auto;

            .name {
                width: 8em;
                padding: layout.$padding--smallest;

                text-align: right;
                text-transform: uppercase;
            }

            .value {
                padding: layout.$padding--smallest;

                background-color: color.$blue--lightest;
                border-radius: layout.$border-radius;
            }
        }

        button.Checkbox {
            //Note: note sure why the size isn't correct, reverting to old behavior
            width: 16px;
            height: 16px;
        }
    }


}
