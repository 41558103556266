@use '~@strategies/tokens/color';
@use '~@strategies/tokens/font';
@use '~@strategies/tokens/layout';


.ChartsHeader {
    $_height: 38px;

    display: flex;
    flex: 0 1 $_height;
    height: $_height;
    align-items: center;

    padding: 0 !important;

    h5 {
        flex: 1 1 auto;

        margin: 0;
        padding: 0 layout.$padding--small;

        font-weight: font.$weight--medium;
        font-size: font.$size--regular;
    }

    .chart-legend {
        display: flex;
        flex: 0 1 $_height;

        height: $_height;

        margin: 0;
        background: color.$blue--light;
        overflow: visible;

        svg {
            z-index: 3;

            width: 50%;
            height: 50%;
            margin: auto;
        }

        &:hover {
            ul {
                opacity: 1;
                pointer-events: initial;

                * {
                    pointer-events: initial;
                }
            }
        }
    }

    ul {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 2;

        width: max-content;
        margin: 0;
        padding: layout.$padding;
        padding-right: calc(#{layout.$padding} + #{$_height});

        background: rgba(color.$blue--light, .85);
        list-style: none;
        opacity: 0;
        pointer-events: none;
        transition: opacity .15s;

        * {
            pointer-events: none;
        }
    }

    li {
        + li {
            margin-top: layout.$padding--smallest / 3;
        }

        div {
            display: inline-block;
            vertical-align: middle;

            width: font.$size--xsmall;
            height: font.$size--xsmall;

            background-color: color.$black;
            border-radius: 50%;
        }

        span {
            display: inline-block;
            vertical-align: middle;

            margin-left: layout.$padding--smallest;

            white-space: pre;
            font-size: font.$size--xsmall;
            letter-spacing: 0;
            line-height: 0.9;
            text-transform: uppercase;
            text-align: left;
        }
    }

}
