@use '~@strategies/tokens/color';
@use '~@strategies/tokens/font';
@use '~@strategies/tokens/layout';

.MenuBar {
    .scenario-name-wrapper {
        border-right: 2px solid color.$blue--lightest;
        border-left: 2px solid color.$blue--lightest;

        display: flex;
        align-items: center;
        text-align: center;
        background: color.$white;

        .scenario-name {
            display: flex;
            width: 90%;
            border-radius: layout.$border-radius * 2;
            overflow: hidden;
            margin: auto;
        }

        label {
            display: flex;
            vertical-align: middle;
            align-items: center;
            align-content: center;

            height: auto;
            padding: layout.$padding--smallest;

            color: color.$grey;
            font-size: font.$size--xsmall;
            font-weight: font.$weight--regular;
            text-transform: uppercase;

            background: color.$blue--lightest;
        }

        input {
            display: inline-block;

            padding: 0 layout.$padding--smallest;

            font-size: $font_size-regular;
            text-overflow: ellipsis;

            background: color.$blue--light;
            border: none;
            overflow: hidden;
            outline: none !important;
        }
    }

  .file-actions {
    display: flex;
    z-index: 1;

    height: $dims_button-size;
    padding: 0;
    margin: auto 0;
    margin-right: 2rem;

    background: none;
    border-radius: 0 $box_border-radius $box_border-radius 0;
    box-shadow: $box_shadow;
    overflow: hidden;

    .show-save-toggle:hover {
        cursor: initial;
    }
  }

  .SaveOptions {
      $_top: $dims_button-size * 1.5;

      position: absolute;
      left: 94%;
      top: $_top * 1.3;

      min-width: 250px;
      padding: $dims_padding-large;

      background: $color_bg-light;
      border-radius: $box_border-radius;
      box-shadow: $box_shadow;
      opacity: 0;
      pointer-events: none;
      transition: top .4s, opacity .3s;

      * {
          pointer-events: none;
      }

      &:before {
          content: '';
          position: absolute;
          top: -30%;
          display: block;

          height: 100%;
          width: 100%;

          pointer-events: none;
      }

      &.active, &:hover {
          top: $_top;

          opacity: 1;
          pointer-events: all;
          transition: top .4s, opacity .3s;

          &:before {
              pointer-events: all;
          }

          * {
              pointer-events: inherit;
          }
      }

      button {
          width: 100%;

          border-radius: $box_border-radius;
          box-shadow: $box_shadow;

          &:nth-of-type(2) {
              margin-top: $dims_padding;
          }
      }
  }

}

