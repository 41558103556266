.SpecialProjects {
    position: fixed;
    display: flex;

    button {
        display: flex;
        align-items: center;
        align-content: center;

        width: 40px;
        height: 40px;
        padding: 0;

        background: $color_bg-medium;
        border-radius: $box_border-radius--small;
        box-shadow: $box_shadow;

        + button {
            margin-left: $dims_padding;
        }

        img {
            width: 29px;
            margin: auto;
        }

        span {
            position: absolute;
            top: -1.5em;
            right: -.75em;

            color: $color_font-light;
            font-size: $font_size-xsmall;
            padding: .75em 1em .5em;
            border-radius: 50%;


            background: $color_light-blue;
        }
    }

}
