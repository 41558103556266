@import '../../core/ui';
@import './ui/ChartsPanel';
@import './ui/ProjectSettings';
@import './ui/GlobalSettingsModal';
@import './ui/MenuBar';
@import './ui/SpecialProjects';
@import './charts/charts';

//NOTE: not sure why the padding is off, but this override seems to be required for philly rails
.ChartsPanel {
    .chartFilter {
        .filter {
            vertical-align: middle;
        }
        .indChartsLabel {
            margin-top: 0.25em;
        }
    }
}