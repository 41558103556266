@use '~@strategies/tokens/color';
@use '~@strategies/tokens/font';
@use '~@strategies/tokens/layout';


.SelectionInfo {
  position: fixed;
  top: 0;
  right: 0;

  width: 214px;

  pointer-events: none;

  .panel {
    margin-top: layout.$padding--small;
    margin-right: layout.$padding--small;
    padding: 0;

    font-weight: font.$weight--regular;

    background: color.$white;
    border-radius: layout.$border-radius--small;
    overflow: hidden;
    pointer-events: all;
    box-shadow: layout.$box-shadow;
  }

  .title-bar {
    align-items: center;

    padding: layout.$padding--small layout.$padding;

    color: color.$blue--lightest;
    font-size: font.$size--large;

    background-color: color.$blue;
    overflow: visible;
  }

  .content {
    padding: layout.$padding--small;
  }

  .row {
    display: flex;

    margin-bottom: layout.$padding--small;

    .name {
      width: 8.571rem;
      padding: layout.$padding--small;

      text-align: right;
      text-transform: uppercase;
    }

    .value {
      width: 100%;
      padding: layout.$padding--small;

      background-color: color.$blue--lightest;
      border-radius: layout.$border-radius;
    }
  }

  .buttons {
    display: flex;

    button {
      flex: 1;
      width: 100%;
      margin-top: layout.$padding;

      border: 1px solid color.$blue;
    }
  }


}
