@use '~@strategies/tokens/color';
@use '~@strategies/tokens/font';
@use '~@strategies/tokens/layout';


.FileModal {
    z-index: 999;

    .Modal {
        &--window {
            width: 90%;
            max-width: 900px;
        }
    }

    ul  {
        max-height: 60vh;
        padding: 0;

        list-style: none;
        overflow-y: auto;
    }

    h3 {
        margin-top: 0;
        font-size: font.$size--large;
        font-weight: font.$weight--medium;
    }

    .current-file {
        .FileItem {
            border-top: 1px solid color.$black;
        }

        + h3 {
            margin-top: layout.$padding;
        }
    }

    .FileItem {
        display: flex;

        padding: layout.$padding;

        border-bottom: 1px solid color.$black;
    }

    li {
        background: color.$white;
        cursor: pointer;
        transition: background .2s;

        &:first-of-type {
            border-top: 1px solid color.$black;
        }

        &:hover {
            background: color.$blue--lightest;
            transition: background .2s;
        }

        &.selected {
            background: color.$blue--light;
            transition: background .3s;

            &:hover {
                background: color.$blue--light__alt;
                transition: background .3s;
            }
        }
    }

    .project-info {
        flex: 1 0 auto;

        h4 {
            margin: 0 auto layout.$padding--small;

            font-size: font.$size--large;
            font-weight: font.$weight--medium;
        }

        p {
            margin: 0;

            color: color.$grey;
            font-size: font.$size--regular;
        }
    }

    .project-details {
        display: flex;
        flex: 0 1 max-content;
        flex-direction: column;
        align-items: center;

        color: color.$grey;

        div {
            width: 100%;
            margin: auto;
            line-height: 1.5em;
            padding: layout.$padding--small * 0.5 layout.$padding--small;
            font-size: font.$size--regular;
            text-overflow: ellipsis;
            white-space: nowrap;

            background: color.$blue--lightest;
            border-radius: layout.$border-radius--large;

            + div {
                margin-top: layout.$padding--small;
            }
        }

        span {
            vertical-align: middle;

            margin: auto;
            margin-right: layout.$padding--small;

            font-size: font.$size--xsmall;
            font-weight: font.$weight--regular;;
            text-transform: uppercase;
        }
    }

    .project-actions {
        display: flex;
        flex-direction: column;

        margin-left: layout.$padding;

        button {
            display: inline-block;
            vertical-align: middle;

            margin: auto;

            box-shadow: none;
            background: none;
        }
    }

    .status {
        height: 150px;

        color: color.$grey;
        font-size: font.$size--regular;
        text-align: center;

        span {
            top: 60px;
            margin: auto;
            vertical-align: middle;
        }
    }

    footer {
        display: flex;
        align-items: right;

        .Button {
            margin: 0;
            margin-left: layout.$padding;

            &.secondary {
                margin-left: auto;
            }

            &.create-fresh {
                margin-left: 0;
            }
        }
    }

}
