.ExampleChart {
  span {
    display: inline-block;
    border: 1px solid #ddd;
    font-size: 8px;

    &.highlight {
      border: 1px solid #49128c;

    }
  }
}
.LinearSfChart {
  span {
    display: inline-block;
    border: 1px solid #ddd;
    font-size: 8px;
  }
}
