@use '~@strategies/tokens/color';
@use '~@strategies/tokens/font';
@use '~@strategies/tokens/layout';

.Filters {
    padding: layout.$padding--smallest;

    border-bottom: 1px solid color.$grey;

    ul {
        display: flex;
        flex-wrap: wrap;
        gap: layout.$padding--smallest;

        padding: 0;
        margin: 0;

        list-style: none;
    }

    li {
        flex: 0 1 max-content;
        display: inline-flex;
        align-items: center;
        padding: layout.$padding--smallest / 3;
        padding-right: layout.$padding--smallest;

        color: color.$white;
        text-transform: uppercase;
        font-weight: font.$weight--medium;

        border-radius: layout.$border-radius--small;
        cursor: pointer;
        opacity: 1;
        transition: opacity .15s;

        &:hover {
            opacity: .8;
            transition: opacity .15s;
        }

        .Checkbox {
            margin-right: layout.$padding--smallest / 3;
            pointer-events: none;
        }
    }

}
