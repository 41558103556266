.DashiChart {
    display: flex;
    flex-direction: column;

    > div {
        flex: 1 1 auto;
        padding: $dims_padding $dims_padding-large;
    }

    .xAxis{
      text-transform: uppercase;
      font-size: 1.5rem;
      position: absolute;
      padding: 10px 0;
    }
}

@import './SummaryChart';
