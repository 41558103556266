.ProjectSettings {

    section {
        &.top {
            border-top: none !important;

            &:first-of-type {
                display: none;
            }

            .hide-labels {
                .ReactForm__Input {
                    width: 140px;
                }
            }

            .project-name {
                flex: 1 1 325px;
            }
        }

        &.fundraising {
            .ReactForm__Group-children {
                align-items: last baseline;
            }

            .cost-sibling {
                display: flex;
                align-items: last baseline;

                .ReactForm__Input {
                    width: 250px;
                }

                button {
                    top: -$dims_padding;

                    padding: $dims_padding * 0.5 $dims_padding;
                    margin: 0 0 0 $dims_padding * 2;

                    color: $color_light-blue;
                    font-size: $font_size-small;
                    font-weight: 500;

                    background: $color_bg-light;
                    border-radius: $box_border-radius--medium;
                    box-shadow: $box_shadow;

                    &:hover {
                        background: $color_lightest-blue;
                    }
                }
            }
        }
    }

    .AccessOption {
        + .AccessOption {
            margin-top: .2em;
        }

        .ReactForm__Group-children {
            align-items: center;
        }

        .ReactForm__Input:not(.readonly) {
            width: 95px;
        }

        label {
            width: 85px;
            font-weight: 400;
            text-transform: none;
        }
    }

    .finish-axons {
        width: 100%;
        margin: 3em 0 1em;

        div {
            width: 50%;
            margin: 0;
            display: inline-block;

            &:nth-of-type(2) {
                text-align: right;
            }
        }

        img {
            width: 90%;
            margin: auto;
        }
    }
}
